import * as React from "react"
import Layout from "../layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container">
        <div className="not-found">
          <h2>The Page Not Found</h2>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
